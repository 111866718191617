import React from 'react';
import { Icon } from 'react-icons-kit';
import { github, linkedin, envelop } from 'react-icons-kit/icomoon';

export default class Contact extends React.Component {
  render = () => {
    return (
      <div>
        <h1 id="contact">Contact</h1>
        <div className="contact-link-container">
          <a href="mailto:mike@gllghr.io" rel="noopener noreferrer" target="_blank">
            <Icon size="1.5rem" icon={envelop} style={{ marginRight: '1rem' }} />
            mike@gllghr.io
          </a>
          <a href="https://github.com/mjgall" rel="noopener noreferrer" target="_blank">
            <Icon size="1.5rem" icon={github} style={{ marginRight: '1rem' }} />
            github.com/mjgall
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://linkedin.com/in/mjamesgallagher">
            <Icon size="1.5rem" icon={linkedin} style={{ marginRight: '1rem' }} />
            linkedin.com/in/mjamesgallagher
          </a>
        </div>
      </div>
    );
  };
}
