import React from 'react';

import Layout from '../components/layout';
import WorkHistory from '../components/WorkHistory';
import Interests from '../components/Interests';
import Education from '../components/Education';
import SEO from '../components/seo';
import Contact from '../components/Contact';
import Projects from '../components/Projects';
import Introduction from '../components/Introduction';

export default class IndexPage extends React.Component {
  render = () => {
    return (
      <Layout changeTheme={this.toggleTheme}>
        <SEO title="Mike Gallagher" />
        <Introduction></Introduction>
        <div className="home-container">
          <WorkHistory></WorkHistory>
          <div>
            <Education></Education>
            <Projects></Projects>
            <Interests></Interests>
            <Contact></Contact>
          </div>
        </div>
      </Layout>
    );
  };
}
